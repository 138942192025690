import React from "react";

const ApiList = ({ isShowApiList }) => {
    return (
    <div className={`${isShowApiList ? "active" : ""} show`}>
      <div className="login-form">
        <div className="form-box solid">
        <h1 className="login-text">Api List</h1>
        <label>CruiseConnect Production - </label>
        <br />
        <p>https://jarvis.cruiseconnect.traveltek.net/graphql</p>
        <br />
        <label>CruiseConnect Sandbox - </label>
        <br />
        <p>https://jarvis.sandbox.cruiseconnect.traveltek.net/graphql</p>
        </div>
      </div>
    </div>
    );
};

export default ApiList;
