import React from "react";

function ApiButton({ handleApiClick }) {
  const handleClick = () => {
    handleApiClick();
  };
  return (
    <span onClick={handleClick} className="loginicon">
        Api List
    </span>
  );
}

export default ApiButton;