import React from 'react';
import GraphiQL from 'graphiql';
import { useExplorerPlugin } from '@graphiql/plugin-explorer';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { useState } from 'react';
import ApiList from './ApiList';
import 'graphiql/graphiql.min.css';
import '@graphiql/plugin-explorer/dist/style.css';
import UrlButton from './UrlButton';
import ApiButton from './ApiButton';
import DocsButton from './DocsButton';

const initialEndpoint = 'https://jarvis.cruiseconnect.traveltek.net/graphql'

function GraphiQLWithExplorer() {
  const [query, setQuery, showAttribution] = useState();
  const [isShowApiList, setIsShowApiList] = useState(true);
  const [apiEndpoint, setapiEndpoint] = useState(initialEndpoint);
  const explorerPlugin = useExplorerPlugin({query,onEdit: setQuery, showAttribution});

  const handleApiClick = () => {
    setIsShowApiList((isShowApiList) => !isShowApiList);
  };

  const handleUrlClick = () => {
      const newEndpoint = window.prompt('Choose the new endpoint', initialEndpoint);
      if(newEndpoint !== null){
        setapiEndpoint(newEndpoint)
      }
  };

  const handleDocsClick = () => {
    window.open("https://schema.sandbox.cruiseconnect.traveltek.net/")
  };

  const fetcher = createGraphiQLFetcher({
    url: apiEndpoint
  });

  return (
    <GraphiQL
      fetcher={fetcher}
      query={query}
      onEditQuery={setQuery}
      plugins={[explorerPlugin]}
    >
      <GraphiQL.Logo>
        Traveltek - CruiseConnect
      </GraphiQL.Logo>
      <GraphiQL.Toolbar>
        <UrlButton handleUrlClick={handleUrlClick} />
        <ApiButton handleApiClick={handleApiClick} />
        <DocsButton handleDocsClick={handleDocsClick}/>
        <ApiList isShowApiList={isShowApiList} />
      </GraphiQL.Toolbar>
      <GraphiQL.Footer>
        <p>{apiEndpoint}</p>
      </GraphiQL.Footer>
    </GraphiQL>

  );
}

const App = () => (
  GraphiQLWithExplorer()
);

export default App;
