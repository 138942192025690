import React from "react";

function DocsButton({ handleDocsClick }) {
  const handleClick = () => {
    handleDocsClick();
  };
  return (
    <span onClick={handleClick} className="loginicon">
        Docs
    </span>
  );
}

export default DocsButton;