import React from "react";

function UrlButton({ handleUrlClick }) {
  const handleClick = () => {
    handleUrlClick();
  };
  return (
    <span onClick={handleClick} className="urlicon">
        Change Endpoint
    </span>
  );
}

export default UrlButton;
